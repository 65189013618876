<div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Services</h2>
      <p>Magnam dolores commodi suscipit eius consequatur ex aliquid fuga eum quidem</p>
    </div>

    <div class="row">
      <div class="col-lg-6 order-2 order-lg-1">
        <div class="icon-box mt-5 mt-lg-0" data-aos="fade-up">
          <i class="bx bx-receipt"></i>
          <h4>Est labore ad</h4>
          <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
        </div>
        <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="100">
          <i class="bx bx-cube-alt"></i>
          <h4>Harum esse qui</h4>
          <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
        </div>
        <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-images"></i>
          <h4>Aut occaecati</h4>
          <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
        </div>
        <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="300">
          <i class="bx bx-shield"></i>
          <h4>Beatae veritatis</h4>
          <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
        </div>
      </div>
      <div class="image col-lg-6 order-1 order-lg-2" style='background-image: url("assets/img/services.jpg");' data-aos="fade-left" data-aos-delay="100"></div>
    </div>

  </div>