
    <div class="container">

      <div class="section-title" data-aos="fade-up">
        <h2>Coaches</h2>
        <!-- <p>These are our coaches</p> -->
      </div>

      <div class="row">

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="member" data-aos="zoom-in">
            <div class="member-img">
              <img src="assets/img/team/team-2.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Debbie Lockett</h4>
              <span>Head Coach</span>
              <p>Debbie is chief in charge</p>
            </div>
          </div>
        </div>
        
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="member-img">
              <img src="assets/img/team/team-1.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Andrew Dean</h4>
              <span>Competetive Coach</span>
              <p>Andrew coaches the competetive swimmers.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="member" data-aos="zoom-in" data-aos-delay="200">
            <div class="member-img">
              <img src="assets/img/team/team-3.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Byron Lockett</h4>
              <span>MMWC</span>
              <p>Byron steers the ship in the right direction</p>
            </div>
          </div>
        </div>

      </div>

    </div>

