
  <!-- ======= Hero Section ======= -->
  <section id="hero">
    <div class="hero-container">
     <a href="index.html" class="hero-logo" data-aos="zoom-in"><img src="assets/img/logo.png" alt=""></a>
      <h1 data-aos="zoom-in">Welcome To Bay Eagle Swim Team</h1> -->
      <!-- <h2 data-aos="fade-up">We are swimming club based in Gqeberha (Formerly known as Port Elizabeth)</h2> -->
      <a data-aos="fade-up" data-aos-delay="200" href="#about" class="btn-get-started scrollto">Dive In</a>
    </div>
  </section>
<!-- END Hero-->
  <app-header></app-header>

<section id="home">

</section>

<section id="about" class="about"><app-about></app-about></section>
<section id="team" class="team"><app-team></app-team></section>
<section id="services" class="services"><app-services></app-services></section>
<section id="contact" class="contact section-bg"><app-contact></app-contact></section>
<section id="footer" class="footer"><app-footer></app-footer></section>


