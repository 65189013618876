import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LeafletMap } from './leaflet-map';
declare const L:any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit {

  map: LeafletMap;

  constructor() { }

  ngOnInit(): void {
  //   let map = AfriGIS.map("mapid", {
  //     // replace zoom level with your default
  //     zoom: 15,
  //     // replace center coord with your default
  //     center: L.latLng(-33.9658,25.5463),
  //     zoomControl: true,
  //     mapType: 'Vector',
  //     authkey: 'af10bb89-0934-4477-9cbc-d8697ef9524c'
  // });

  // setTimeout(function () {
  //     map.switchTo("Hybrid");     // Vector or Hybrid or Imagery
  // }, 5000);
  // var marker = L.marker([-33.9658,25.54615]).addTo(map);
  // marker.bindPopup("<b>Bay Eagle Aquatics</b><br>This is where we are").openPopup();

  
  }

    ngAfterViewInit() : void{
      this.map=new LeafletMap('mapid');
    }

  sendMessage(){
    alert('This will send an email to info@bayeagle.co.za');
  }
}
