    <!-- ======= About Us Section ======= -->

        <div class="container">
  
          <div class="section-title" data-aos="fade-up">
            <h2>About Us</h2>
            <p>Offering swimmming lessons for young and old</p>
          </div>
  
          <div class="row">
            <div class="col-lg-6" data-aos="fade-right">
              <div class="image">
                <img src="assets/img/aboutus.jpg" class="img-fluid" alt="">
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-left">
              <div class="content pt-4 pt-lg-0 pl-0 pl-lg-3 ">
                <h3>Bay Eagle Swim Team</h3>
                <p class="fst-italic">
                 
                </p>
                <!-- <ul>
                  <li><i class="bx bx-check-double"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i class="bx bx-check-double"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i class="bx bx-check-double"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul> -->
                <p>
                  Situated at the Bay Eagle Aquatics Centre, we offer swimming for all ages and abilities. </p>
                  <ul>
                    <li>
                      The premises boasts a 50m pool that we have divided into two 25m pools for more training opportunities.
                    </li>
                    <li>
                      There are also two learn to swim pools for our younger swimmers.
                    </li>
                    <li>
                      This allows us to cater to all our swimming levels from our ambitious little eaglets in the learn to swim category, 
                      all the way up to our competitive eagles at the National level and onwards to our Masters. 
                    </li>
                    
                </ul>
              
              </div>
            </div>
          </div>
  
        </div>

