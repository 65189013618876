import * as Leaflet from 'leaflet';

const best = {
    coords: new Leaflet.LatLng(-33.9658,25.5463),
    zoom: 15
}
//-33.9658,25.5463
export class LeafletMap{
    map: Leaflet.Map;

    constructor(id: string){
        this.map = Leaflet.map(id);
        Leaflet
            .tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
            .addTo(this.map);
        
        this.map.setView(best.coords, best.zoom);
        
        let marker = Leaflet.marker(best.coords).addTo(this.map);
         marker.bindPopup("<b>Bay Eagle Aquatics</b><br>This is where we are").openPopup();
    
}

}